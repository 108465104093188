import styled from "styled-components"
import {FontSizes} from "../../../utils/font-sizes";
import {Breakpoints} from "../../../utils/breakpoints";

export const StyledFooterColumn = styled.div.attrs({
    className: "flex flex-col"
})`
`

export const MenuTitle = styled.div`
  font-weight: 500;
  letter-spacing: 1px;
  font-size: ${FontSizes.size14};
`

export const MenuItemWrapper = styled.div`
  margin-bottom: 10px;

  a {
    letter-spacing: 0;
  }

  @media ${Breakpoints.desktop} {
    margin-bottom: 20px;
  }
`
