import * as React from "react"
import {withPrefix} from 'gatsby';
import {StyledLink, StyledLinkText} from "./footer-menu-item.styled";
import {IMenuItem} from "../../menu/menu-item/menu-item";

type FooterMenuItemProps = {
    menuItem: IMenuItem
}

const FooterMenuItem = ({menuItem}: FooterMenuItemProps) => {
    return (
        <StyledLink
            to={withPrefix(menuItem.label.toLowerCase())}>
            <StyledLinkText>
                <span>
                    {menuItem.label}
                </span>
            </StyledLinkText>
        </StyledLink>
    )
}

export default FooterMenuItem
