import * as React from "react"
import {graphql, StaticQuery} from "gatsby"
import {IAllMenuItems} from "../footer/footer";
import {LogoContainer, NavigationWrapper} from "./menu.styled";
import Logo from "../logo/logo";
import MenuItem, {IMenuItem} from "./menu-item/menu-item";
import MobileMenu from "./mobile-menu/mobile-menu";

const MENU_QUERY = graphql`
  fragment MenuItem on WpMenuItem {
      id
      label
      url
      title
      target
    }
    
    query GET_MAIN_MENU {
      allWpMenu(filter: {locations: {in: MAIN_MENU}}) {
        nodes {
          name
          menuItems {
            nodes {
              ...MenuItem
            }
          }
        }
      }
    }
`

interface IMenuProps {
    isFrontPage: boolean
}

const Menu = ({isFrontPage}: IMenuProps) => {
    return (
        <StaticQuery
            query={MENU_QUERY}
            render={(data: IAllMenuItems) => {
                if (data.allWpMenu.nodes[0].menuItems) {
                    const menuItems = data.allWpMenu.nodes[0].menuItems.nodes

                    return (
                        <>
                            <NavigationWrapper>
                                <LogoContainer isFrontPage={isFrontPage}>
                                    <Logo/>
                                </LogoContainer>
                                {
                                    menuItems &&
                                    menuItems.map((menuItem: IMenuItem) => (
                                        <MenuItem key={menuItem.id} menuItem={menuItem} hasActiveStyle={true}
                                                  isFrontPage={isFrontPage}/>
                                    ))
                                }
                            </NavigationWrapper>
                            <MobileMenu menuItems={menuItems} isFrontPage={isFrontPage}/>
                        </>
                    )
                }
                return null
            }}
        />
    )
}

export default Menu
