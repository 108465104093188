import * as React from "react"
import {withPrefix} from "gatsby";
import LogoIcon from "/src/assets/svg/logo_michelatsch.svg";
import {LogoLink} from "./logo.styled";

const Logo = () => {
    return (
        <LogoLink to={withPrefix('/')}>
            <LogoIcon/>
        </LogoLink>
    )
}

export default Logo
