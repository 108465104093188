import * as React from "react"
import {graphql, StaticQuery} from "gatsby"
import {FooterColumn, IMenuItems} from "./footer-column/footer-column";
import {ColumnCenter, FooterColumnsContainer, FooterContainer, FooterRow} from "./footer.styled";
import ContentContainer from "../content-container/content-container";
import Logo from "../logo/logo";

const FOOTER_MENU_QUERY = graphql`
   fragment MenuItem on WpMenuItem {
      id
      label
      url
      title
      target
    }
    
    query GET_FOOTER_MENU {
      allWpMenu(filter: {locations: {nin: MAIN_MENU}}) {
        nodes {
          name
          locations
          menuItems {
            nodes {
              ...MenuItem
            }
          }
        }
      }
    }
`

export interface IAllMenuItems {
    allWpMenu: {
        nodes: IAllMenuItemEntry[]
    }
}

interface IAllMenuItemEntry {
    name: string,
    locations: string[],
    menuItems: IMenuItems
}

function sortMenus(first: IAllMenuItemEntry, second: IAllMenuItemEntry): number {
    if (first.locations[0] > second.locations[0]) {
        return 1
    }
    if (first.locations[0] < second.locations[0]) {
        return -1
    }

    return 0
}

const Footer = () => {
    return (
        <StaticQuery
            query={FOOTER_MENU_QUERY}
            render={(data: IAllMenuItems) => {
                if (data.allWpMenu.nodes) {
                    const menus = data.allWpMenu.nodes
                    menus.forEach(menu => menu.locations = menu.locations.filter(location => location !== null));
                    menus.sort(sortMenus);
                    return (
                        <FooterContainer>
                            <ContentContainer>
                                <FooterColumnsContainer>
                                    {
                                        menus
                                            .map((menu, index) => (
                                                <FooterColumn key={`${menu.name}-${index}`} name={menu.name}
                                                              nodes={menu.menuItems.nodes}/>
                                            ))
                                    }
                                </FooterColumnsContainer>
                                <FooterRow>
                                    <Logo/>
                                    <ColumnCenter>2021 Michelatsch GmbH</ColumnCenter>
                                </FooterRow>
                            </ContentContainer>
                        </FooterContainer>
                    )
                }
                return null
            }}
        />
    )
}

export default Footer
