import * as React from "react"
import styled from "styled-components"
import {Link} from "gatsby";
import {Colors} from "../../../utils/colors";
import {FontSizes} from "../../../utils/font-sizes";
import {Breakpoints} from "../../../utils/breakpoints";

export const NavItem = styled.div`
  margin-right: 50px;

  @media ${Breakpoints.desktop} {
    margin-right: 30px;
  }
`

export const StyledLinkText = styled.div`
  border-bottom: 1px solid transparent;
  font-size: ${FontSizes.size14};
  font-weight: 600;
`

export const StyledLink = styled(({isFrontPage: boolean, ...props}) => <Link {...props} />)`
  color: ${props => props.isFrontPage ? Colors.white : Colors.black};
  letter-spacing: 1px;
  text-decoration: none;
  overflow-wrap: break-word;
  
  &:hover {
    ${StyledLinkText} {
      border-bottom: 1px solid;
    }
  }
  
  &.active {
    ${StyledLinkText} {
      border-bottom: 1px solid ${Colors.black};
    }
  }
`

