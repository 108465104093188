import styled from "styled-components"
import {Link} from "gatsby";
import * as React from "react";

export const LogoLink = styled(props => <Link {...props} />).attrs({
    className: "flex"
})`
  height: 100%;

  svg {
    max-height: 100%;
    max-width: 200px;
  }
`
