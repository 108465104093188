import styled from "styled-components"
import {Colors} from "../../utils/colors";
import {FontSizes} from "../../utils/font-sizes";

export const FooterColumnsContainer = styled.div.attrs({
    className: "grid grid-cols-2 tablet:grid-cols-4 gap-8"
})`
  margin-top: 60px;
  width: 100%;
  column-gap: 70px;
  row-gap: 30px;
`

export const FooterRow = styled.div.attrs({
    className: "grid grid-cols-1 laptop:grid-cols-4 laptop:items-end"
})`
  border-top: 1px solid ${Colors.greyBackground};
  width: 100%;
  margin-top: 25px;
  padding-top: 25px;
  margin-bottom: 50px;
  font-size: ${FontSizes.size14};
  justify-items: center;
  row-gap: 10px;

  svg {
    color: ${Colors.red};
  }
`

export const ColumnCenter = styled.div.attrs({
    className: "col-span-2 text-center"
})`
`

export const FooterContainer = styled.footer`
  border-top: 1px solid ${Colors.greyBackground};
  width: 100%;
  margin-top: 80px;
`
