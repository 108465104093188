import * as React from "react"
import styled from "styled-components"
import {Link} from "gatsby";
import {Colors} from "../../../utils/colors";
import {FontSizes} from "../../../utils/font-sizes";

export const MobileNavItem = styled.div`
  margin-bottom: 30px;
`

export const StyledMobileLink = styled(props => <Link {...props} />)`
  color: ${Colors.white};
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: 600;
  font-size: ${FontSizes.size22};
`

