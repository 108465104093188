import * as React from "react"
import {ReactNode} from "react"
import {ContentWrapper} from "./content-container.styled";

interface IContentContainerProps {
    children: ReactNode
    onlyForMobile?: boolean
}

const ContentContainer = ({children, onlyForMobile = false}: IContentContainerProps) => {
    return (
        <ContentWrapper onlyForMobile={onlyForMobile}>
            {children}
        </ContentWrapper>
    )
}

export default ContentContainer
