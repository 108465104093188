import styled from "styled-components"
import {Breakpoints} from "../../utils/breakpoints";

export const PageWrapper = styled.div.attrs({
    className: "flex flex-col"
})`
  margin: 0 auto;
  max-width: 1200px;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  
  @media ${Breakpoints.desktop} {
    overflow: visible;
  }
`
