import * as React from "react"
import {ReactNode} from "react"
import {Helmet} from "react-helmet"
import {PageWrapper} from "./layout.styled";
import Menu from "../menu/menu";
import Footer from "../footer/footer";

interface ILayoutProps {
    children: ReactNode
    isFrontPage: boolean
}

const Layout = ({children, isFrontPage}: ILayoutProps) => {

    if (typeof window !== "undefined") {
        require("smooth-scroll")('a[href*="#"]', {
            offset: 80
        })
    }

    return (
        <PageWrapper>
            <Helmet
                htmlAttributes={{
                    lang: 'de'
                }}/>
            <Menu isFrontPage={isFrontPage}/>
            <main className={'flex-1 relative'}>{children}</main>
            <Footer/>
        </PageWrapper>
    )
}

export default Layout
