export abstract class FontSizes {
    public static size12 = '12px';
    public static size14 = '14px';
    public static size16 = '16px';
    public static size20 = '20px';
    public static size22 = '22px';
    public static size24 = '24px';
    public static size28 = '28px';
    public static size30 = '30px';
    public static size44 = '44px';
    public static size48 = '48px';
    public static size72 = '72px';
    public static size80 = '80px';
    public static size100 = '100px';
}

export abstract class FontSizesBorder {
    public static size2Solid = '2px solid';
}
