import * as React from "react";
import {useState} from "react";
import {
    MobileNavigationButton,
    MobileNavigationContainer,
    MobileNavigationOverlay,
    MobileNavigationOverlayButton,
    MobileNavigationWrapper,
    OverlayLogo, OverlaySocialIcons
} from "./mobile-menu.styled";
import HamburgerIcon from "/src/assets/svg/ic_menu.svg";
import CloseIcon from "/src/assets/svg/ic_close.svg";
import FacebookIcon from "/src/assets/svg/ic_facebook.svg";
import InstaIcon from "/src/assets/svg/ic_instagram.svg";
import {IMenuItem} from "../menu-item/menu-item";
import {LogoContainer} from "../menu.styled";
import Logo from "../../logo/logo";
import MobileMenuItem from "../mobile-menu-item/mobile-menu-item";

type MobileMenuItemProps = {
    menuItems: [IMenuItem],
    isFrontPage: boolean
}

const MobileMenu = (props: MobileMenuItemProps) => {
    const [mobileNavVisible, setMobileNavVisible] = useState(false);

    return (
        <>
            <MobileNavigationWrapper>
                <MobileNavigationButton onClick={() => setMobileNavVisible(!mobileNavVisible)}
                                        isFrontPage={props.isFrontPage}>
                    <HamburgerIcon/>
                </MobileNavigationButton>
                <LogoContainer isFrontPage={props.isFrontPage}>
                    <Logo/>
                </LogoContainer>
            </MobileNavigationWrapper>
            <MobileNavigationOverlay isVisible={mobileNavVisible}>
                <MobileNavigationContainer>
                    <MobileNavigationOverlayButton onClick={() => setMobileNavVisible(!mobileNavVisible)}>
                        <CloseIcon/>
                    </MobileNavigationOverlayButton>
                    <OverlayLogo>
                        <Logo/>
                    </OverlayLogo>
                    {
                        props.menuItems &&
                        props.menuItems.map((menuItem: IMenuItem) => (
                            <MobileMenuItem key={menuItem.id} label={menuItem.label}/>
                        ))
                    }
                    <OverlaySocialIcons>
                        <FacebookIcon/>
                        <InstaIcon/>
                    </OverlaySocialIcons>
                </MobileNavigationContainer>
            </MobileNavigationOverlay>
        </>
    )
}

export default MobileMenu
