import * as React from "react"
import styled from "styled-components"
import {Link} from "gatsby";
import {Colors} from "../../../utils/colors";
import {FontSizes} from "../../../utils/font-sizes";

export const StyledLinkText = styled.div`
  font-size: ${FontSizes.size14};
  font-weight: 400;
`

export const StyledLink = styled(props => <Link {...props} />)`
  color: ${Colors.black};
  text-decoration: none;
  overflow-wrap: break-word;

  &:hover {
    span {
      border-bottom: 1px solid;
    }
  }
`

