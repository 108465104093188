import * as React from "react"
import {MenuItemWrapper, MenuTitle, StyledFooterColumn} from "./footer-column.styled";
import FooterMenuItem from "../footer-menu-item/footer-menu-item";
import {IMenuItem} from "../../menu/menu-item/menu-item";

export interface IMenuItems {
    nodes: [IMenuItem]
    name: string
}

export const FooterColumn = (props: IMenuItems) => {
    return (
        <StyledFooterColumn>
            <MenuItemWrapper>
                <MenuTitle>{props.name}</MenuTitle>
            </MenuItemWrapper>
            {props.nodes.map((menuItem, index) => (
                <MenuItemWrapper key={`${menuItem.id}-${index}`}>
                    <FooterMenuItem menuItem={menuItem}/>
                </MenuItemWrapper>
            ))}
        </StyledFooterColumn>
    )
}
