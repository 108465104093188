import styled from "styled-components"
import {Breakpoints} from "../../utils/breakpoints";
import {Colors} from "../../utils/colors";

export const NavigationWrapper = styled.div.attrs({
    className: "flex flex-row items-end"
})`
  z-index: 1;
  height: 70px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  display: none;

  @media ${Breakpoints.laptop} {
    display: flex;
  }
`

export const LogoContainer = styled.div<{ isFrontPage: boolean }>`
  height: 100%;

  svg {
    margin-top: 22px;
    color: ${props => props.isFrontPage ? Colors.white : Colors.red};
  }

  @media ${Breakpoints.laptop} {
    margin-right: 80px;
  }
`
