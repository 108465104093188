import styled from "styled-components"
import {Breakpoints} from "../../utils/breakpoints";

export const ContentWrapper = styled.div<{ onlyForMobile: boolean }>`
  width: 80%;
  margin: auto;

  @media ${Breakpoints.laptop} {
    width: ${props => props.onlyForMobile ? 100 : 80}%
  }
`
