import * as React from "react"
import {withPrefix} from 'gatsby';
import {NavItem, StyledLink, StyledLinkText} from "./menu-item.styled";

export interface IMenuItem {
    id: string
    label: string
    url: string
    title: string
    target: string
}

type MenuItemProps = {
    menuItem: IMenuItem,
    hasActiveStyle?: boolean
    isFrontPage: boolean
}

const MenuItem = ({menuItem, hasActiveStyle = false, isFrontPage}: MenuItemProps) => {
    const activeClass = hasActiveStyle ? 'active' : ''
    return (
        <NavItem>
            <StyledLink activeClassName={activeClass} isFrontPage={isFrontPage}
                        partiallyActive={hasActiveStyle}
                        to={withPrefix(menuItem.label.toLowerCase())}>
                <StyledLinkText>
                    {menuItem.label}
                </StyledLinkText>
            </StyledLink>
        </NavItem>
    )
}

export default MenuItem
