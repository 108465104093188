import * as React from "react"
import {withPrefix} from 'gatsby';
import {MobileNavItem, StyledMobileLink} from "./mobile-menu-item.styled";

export interface IMobileMenuItem {
    label: string
}

const MobileMenuItem = (menuItem: IMobileMenuItem) => {
    return (
        <MobileNavItem>
            <StyledMobileLink to={withPrefix(menuItem.label.toLowerCase())}>
                {menuItem.label}
            </StyledMobileLink>
        </MobileNavItem>
    )
}

export default MobileMenuItem
