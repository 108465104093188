import styled from "styled-components"
import {Breakpoints} from "../../../utils/breakpoints";
import {Colors} from "../../../utils/colors";

export const MobileNavigationWrapper = styled.div.attrs({
    className: "flex flex-row justify-center items-center"
})`
  width: 100%;
  position: relative;
  z-index: 1;
  height: 70px;
  margin-bottom: 20px;

  @media ${Breakpoints.laptop} {
    display: none;
    margin-bottom: 0;
  }
`

export const MobileNavigationButton = styled.a<{ isFrontPage: boolean }>`
  position: absolute;
  left: 20px;

  svg {
    width: 30px;
    height: auto;
    margin-right: 20px;
    margin-top: 50px;
    color: ${props => props.isFrontPage ? Colors.white : Colors.red};
  }
`

export const MobileNavigationOverlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: ${Colors.red};
  display: ${props => props.isVisible ? 'block' : 'none'}
`

export const MobileNavigationContainer = styled.div.attrs({
    className: "flex flex-col items-center justify-center"
})`
  color: ${Colors.white};
  padding: 30px;
  height: 100%;
`

export const MobileNavigationOverlayButton = styled.a`
  position: absolute;
  left: 20px;
  top: 48px;

  img {
    width: 20px;
    height: auto;
  }
`

export const OverlayLogo = styled.div`
  position: absolute;
  top: 22px;
`

export const OverlaySocialIcons = styled.div.attrs({
    className: "flex flex-row"
})`
  position: absolute;
  bottom: 30px;

  svg {
    width: 30px;
    height: auto;

    &:last-child {
      margin-left: 20px;
    }
  }
`
